dmx.Component('query-manager', {

    initialData: {
        data: {}
    },

    attributes: {},

    methods: {
        set: function(key, value) {
            this.setQueryParam(key, value);
        },

        remove: function(key) {
            this.setQueryParam(key);
        },

        removeAll: function() {
            this.setQueryParam();
        }
    },

    render: function(node) {
        this.update();
    },

    update: function() {
        this.set('data', this.parseQuery(window.location.search));
    },

    setQueryParam: function(key, value) {
        var updated = false;

        if (value == null) {
            if (key == null) {
                this.data.data = {};
                updated = true;
            } else if (this.data.data[key]) {
                delete this.data.data[key];
                updated = true;
            }
        } else if (this.data.data[key] != value) {
            this.data.data[key] = value;
            updated = true;
        }

        if (updated) {
            if (window.URLSearchParams) { // use URLSearchParams if available
                var url = new URL(window.location);
                url.search = new URLSearchParams(this.data.data);
                window.history.pushState(null, null, url);
            } else {    
                window.history.pushState(null, null, window.location.pathname + this.buildQuery(this.data.data) + window.location.hash);
            }
            dmx.requestUpdate();
        }
    },

    buildQuery: function(data) {
        var keys = Object.keys(data);

        return keys.length ? '?' + keys.reduce(function(query, key) {
            if (query) query += '&';
            query += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            return query;
        }, '') : '';
    },

    parseQuery: function(query) {
        query = query.replace(/^\?/, '');

        return query.split('&').reduce(function(data, part) {
            var p = part.replace(/\+/g, ' ').split('=');
            if (p[0]) {
                data[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
            }
            return data;
        }, {});
    }

});
